<template>
  <div v-if="!isLoading" class="row">
    <div class="card">
      <div class="card-body">
        <h1>Reports for {{ user.firstName }} {{ user.lastName }}</h1>
        <div v-if="user.enabled">
          <b-tooltip target="refresh-button" triggers="hover"
                     title="Refreshing reports can take some time. Click here to update the user's report.">
            <button id="refresh-button" class="btn-primary button" type="button" @click="updateCertifications">
              Refresh Reports
            </button>
          </b-tooltip>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-sm card" v-for="certification in certifications" :key="certification.idCertification"
           :id="certification.idCertifcation" style="margin: 5px;">
        <div class="card-header">
          <label>{{ certification.title }}</label>
          <div>
            <span>{{ certification.gradedModuleItems }} out of {{ certification.gradeableModuleItems }} of items submitted</span>
            <br/>
            <span
                v-if="certification.gradedPercent !== null && certification.gradedPercent !== undefined && certification.gradedPercent.toFixed(3) > 0.000">
            Graded score: {{ certification.gradedPercent.toFixed(3) }}%
          </span>
<!--            <CertificationPdfGenerator :certification-name="certification.title" :completion-date=""-->
          </div>
        </div>
        <div class="accordion card-body" :id="'certAccordion' + certification.idCertification">
          <div class="accordion-item" v-for="module in certification.modules" :key="module.idModule">
            <h2 class="accordion-header" :id="module.name + module.idModule">
              <button class="accordion-button collapsed" type="button" data-toggle="collapse"

                      :data-target="'#module' + module.idModule + '_cert' + certification.idCertification"
                      aria-expanded="false"
                      :aria-controls="'module' + module.idModule + '_cert' + certification.idCertification">
                <div class="col-sm">
                  {{ module.name }}
                </div>
                <!--              <div style="font-size: 75%;" class="col-sm">-->
                <!--                <i>{{ module.gradedItems }} out of {{ module.gradeableItems }} gradeable items submitted</i>-->
                <!--              </div>-->
                <!--              <div class="col-sm" style="border-right-style: solid;">-->
                <!--                {{ module.name }}-->
                <!--              </div>-->
                <!--              <div style="font-size: 75%;" class="col-sm">-->
                <!--                <i>{{ module.gradedItems }} out of {{ module.gradeableItems }} gradeable items submitted</i>-->
                <!--              </div>-->
                <!--              <div style="font-size: 75%; border-left-style: solid;" class="col-sm">-->
                <!--                <i>{{ module.gradedItems }} out of {{ module.gradeableItems }} gradeable items submitted</i>-->
                <!--              </div>-->

              </button>
              <div style="font-size: 45%">
                <i>{{ module.gradedItems }} out of {{ module.gradeableItems }} items submitted</i>
              </div>
              <hr/>
            </h2>
            <div :id="'module' + module.idModule + '_cert' + certification.idCertification"
                 class="accordion-collapse collapse" :aria-labelledby="module.name + module.idModule">
              <strong v-for="item in module.moduleItems" :key="item.idModuleItem"
                      style="padding: 5px; color: black; opacity: .9; font-size: 18px;">
                {{ item.title }}
                <!--                <span style="color: royalblue; font-weight: bold;" v-if="!item.gradeable">{{-->
                <!--                  '\u2605'-->
                <!--                }}</span>-->
                <div v-if="item.gradeable">

                  <div class="row" v-if="(item.assignments[0].grade !== null
                && item.assignments[0].grade !== undefined && item.assignments[0].grade.score !== null)">
                    <div class="col-sm"><span>Graded Percent:</span></div>
                    <div class="col-sm"
                         v-if="item.assignments[0].grade.score / item.assignments[0].pointsPossible * 100 >= 69"
                    ><span style="color: darkgreen;">{{
                        (item.assignments[0].grade.score / item.assignments[0].pointsPossible * 100).toFixed(3)
                      }}%</span></div>
                    <div class="col-sm" v-else style="color: red;">{{
                        (item.assignments[0].grade.score / item.assignments[0].pointsPossible * 100).toFixed(3)
                      }}%
                    </div>
                  </div>
                  <div v-else class="col-sm">
                    <span style="color: blue;">Not submitted yet</span>
                  </div>

                </div>
                <hr/>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div v-else>
    <Loader :loading-value="'Reports for ' + user.firstName + ' ' + user.lastName"/>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, onBeforeMount, ref} from "vue";
import {API_URL} from "../../../../../Constants";
import axios from "axios";
import {
  certificationsByParaCall, getCertificationByUserId,
  updateCertificationByParaCall,
  updateCertificationByUserCall
} from "@/Helpers/ApiCalls/ReportAPICalls";
import Loader from "@/components/Loader";
import CertificationPdfGenerator from "@/components/Reporting/Certifications/CertificationPdfGenerator";

export default {
  name: "ParaCertifications",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CertificationPdfGenerator,
    Loader
  },
  props: {
    idUser: String,
    onUserPage: Boolean
  },
  setup(props, context) {
    const store = useStore();
    const authToken = computed(() => store.getters['auth/authenticated']);
    const authUser = computed(() => store.getters['auth/user']);
    const user = ref({});
    const certifications = ref([]);
    const isLoading = ref(true);

    onBeforeMount(() => {
      getUser();
    });

    async function getUser() {
      let get_uri = API_URL + "/user/byId?_idUser=" + parseInt(atob(props.idUser));
      await axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        user.value = result.data;
      }).finally(() => {
        if (!props.onUserPage) {
          updateCertifications();
        } else {
          getCertifications();
        }
      })
    }

    async function getCertifications() {
      await axios.get(getCertificationByUserId(parseInt(atob(props.idUser))), {
        headers: {
          "Authorization": "Bearer " + authToken.value
        },
      }).then((result) => {
        certifications.value = result.data;
      }).finally(() => {
        isLoading.value = false;
      })
    }

    async function updateCertifications() {
      await axios.get(updateCertificationByUserCall(parseInt(atob(props.idUser))), {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        certifications.value = result.data;
        isLoading.value = false;
      })
    }

    return {
      user,
      certifications,
      isLoading,
      updateCertifications
    }
  }
}
</script>

<style scoped>
span {
  font-weight: normal;
  opacity: .9;
  color: #000;
}

</style>